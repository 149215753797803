import dynamic from 'next/dynamic';

const ChatIntegrationGoldFeatureIcon = dynamic(
  () => import('@/components/common/Icons/ChatIntegrationGoldFeatureIcon')
);
const NplCalender = dynamic(() => import('./collection/NplCalender'));
const ContentLibraryGoldIcon = dynamic(
  () => import('@/components/common/Icons/ContentLibraryGoldIcon')
);
const FileUploadIcon = dynamic(
  () => import('@/components/common/Icons/FileUploadIcon/FileUploadIcon')
);
const LiveEventsFeatureGoldIcon = dynamic(
  () => import('@/components/common/Icons/LiveEventsFeatureGoldIcon')
);
const MagicReachFeatureIcon = dynamic(
  () => import('@/components/common/Icons/MagicReachFeatureIcon')
);
const MonetizationFeatureGoldIcon = dynamic(
  () => import('@/components/common/Icons/MonetizationFeatureGoldIcon')
);
const VisitIcon = dynamic(
  () => import('@/components/common/Icons/VisitIcon/VisitIcon')
);
const Web3FeatureIcon = dynamic(
  () => import('@/components/common/Icons/Web3FeatureIcon')
);
const AccountBalanceWallet = dynamic(
  () => import('@/components/common/IconsV2/AccountBalanceWallet')
);
const AddLink = dynamic(
  () => import('@/components/common/IconsV2/AddLink')
);
const AlertCircle = dynamic(
  () => import('@/components/common/IconsV2/AlertCircle')
);
const AnalyticsStroke = dynamic(
  () => import('@/components/common/IconsV2/AnalyticsStroke')
);
const ArrowForward = dynamic(
  () => import('@/components/common/IconsV2/ArrowForward')
);
const BookOpen = dynamic(
  () => import('@/components/common/IconsV2/BookOpen')
);
const Cached = dynamic(() => import('@/components/common/IconsV2/Cached'));
const CalendarDays = dynamic(
  () => import('@/components/common/IconsV2/CalendarDays')
);
const CalendarEvent = dynamic(
  () => import('@/components/common/IconsV2/CalendarEvent/CalendarEvent')
);
const CalendarPlus01 = dynamic(
  () => import('@/components/common/IconsV2/CalendarPlus01')
);
const CameraIcon = dynamic(
  () => import('@/components/common/IconsV2/CameraIcon')
);
const CheckCircle = dynamic(
  () => import('@/components/common/IconsV2/CheckCircle')
);
const CheckIcon = dynamic(
  () => import('@/components/common/IconsV2/CheckIcon')
);
const Clock = dynamic(
  () => import('@/components/common/IconsV2/Clock/Clock')
);
const CollectionsBookmark = dynamic(
  () => import('@/components/common/IconsV2/CollectionsBookmark')
);
const CopyContentIcon = dynamic(
  () => import('@/components/common/IconsV2/CopyContentIcon')
);
const CopyContentV2 = dynamic(
  () => import('@/components/common/IconsV2/CopyContentV2/CopyContent')
);
const CopyLinkIcon = dynamic(
  () => import('@/components/common/IconsV2/CopyLinkIcon')
);
const Dash = dynamic(() => import('@/components/common/IconsV2/Dash'));
const DeleteOutline = dynamic(
  () => import('@/components/common/IconsV2/DeleteOutline')
);
const DollarIconGrey = dynamic(
  () => import('@/components/common/IconsV2/DollarIconGrey')
);
const DragIndicatorReorder = dynamic(
  () => import('@/components/common/IconsV2/DragIndicatorReorder')
);
const EditDocument = dynamic(
  () => import('@/components/common/IconsV2/EditDocument')
);
const Exercise = dynamic(
  () => import('@/components/common/IconsV2/Exercise/Exercise')
);
const FeaturePlay = dynamic(
  () => import('@/components/common/IconsV2/FeaturePlay')
);
const File04 = dynamic(() => import('@/components/common/IconsV2/File04'));
const FileUpload = dynamic(
  () => import('@/components/common/IconsV2/FileUpload')
);
const FormsAddOn = dynamic(
  () => import('@/components/common/IconsV2/FormsAddOn')
);
const GiftCard = dynamic(
  () => import('@/components/common/IconsV2/GiftCard')
);
const Globe = dynamic(
  () => import('@/components/common/IconsV2/Globe/Globe')
);
const HourGlassIcon = dynamic(
  () => import('@/components/common/IconsV2/HourGlassIcon')
);
const Hub = dynamic(() => import('@/components/common/IconsV2/Hub'));
const IconLLeft = dynamic(
  () => import('@/components/common/IconsV2/IconLLeft')
);
const IconLRight = dynamic(
  () => import('@/components/common/IconsV2/IconLRight')
);
const IconNE = dynamic(() => import('@/components/common/IconsV2/IconNE'));
const IconSLeft = dynamic(
  () => import('@/components/common/IconsV2/IconSLeft')
);
const InfoCircleIcon = dynamic(
  () => import('@/components/common/IconsV2/InfoCircleIcon')
);
const InfoCircleOutlineIcon = dynamic(
  () => import('@/components/common/IconsV2/InfoCircleOutlineIcon')
);
const InfoQuestion = dynamic(
  () => import('@/components/common/IconsV2/InfoQuestion')
);
const LibraryAdd = dynamic(
  () => import('@/components/common/IconsV2/LibraryAdd')
);
const LibraryStroke = dynamic(
  () => import('@/components/common/IconsV2/LibraryStroke')
);
const Link = dynamic(() => import('@/components/common/IconsV2/Link'));
const LiveSession = dynamic(
  () => import('@/components/common/IconsV2/LiveSession')
);
const LiveTV = dynamic(() => import('@/components/common/IconsV2/LiveTV'));
const LockIcon = dynamic(
  () => import('@/components/common/IconsV2/LockIcon')
);
const MagicReach = dynamic(
  () => import('@/components/common/IconsV2/MagicReach')
);
const MinusCircleFill = dynamic(
  () => import('@/components/common/IconsV2/MinusCircleFill')
);
const MoreVerticle = dynamic(
  () => import('@/components/common/IconsV2/MoreVerticle')
);
const NPLCourse = dynamic(
  () => import('@/components/common/IconsV2/NPLCourse')
);
const NPLTrendUp = dynamic(
  () => import('@/components/common/IconsV2/NPLTrendUp')
);
const People = dynamic(() => import('@/components/common/IconsV2/People'));
const Place = dynamic(
  () => import('@/components/common/IconsV2/Place/Place')
);
const PlayOverlayVideo = dynamic(
  () => import('@/components/common/IconsV2/PlayOverlayVideo')
);
const Readings = dynamic(
  () => import('@/components/common/IconsV2/Readings')
);
const PlusIcon = dynamic(
  () => import('@/components/common/IconsV2/PlusIcon')
);
const QandA = dynamic(() => import('@/components/common/IconsV2/QandA'));
const SentimentDissatisfied = dynamic(
  () => import('@/components/common/IconsV2/SentimentDissatisfied')
);
const SmartWebsite = dynamic(
  () => import('@/components/common/IconsV2/SmartWebsite')
);
const SupportAgent = dynamic(
  () => import('@/components/common/IconsV2/SupportAgent')
);
const TokenNftWeb3 = dynamic(
  () => import('@/components/common/IconsV2/TokenNftWeb3')
);
const UnlockIcon = dynamic(
  () => import('@/components/common/IconsV2/UnlockIcon')
);
const User01 = dynamic(
  () => import('@/components/common/IconsV2/User01/User01')
);
const UserEditIcon = dynamic(
  () => import('@/components/common/IconsV2/UserEditIcon/UserEditIcon')
);
const UsersCheck = dynamic(
  () => import('@/components/common/IconsV2/UsersCheck')
);
const VideoLibrary = dynamic(
  () => import('@/components/common/IconsV2/VideoLibrary/VideoLibrary')
);
const Warning = dynamic(
  () => import('@/components/common/IconsV2/Warning')
);
const Website = dynamic(
  () => import('@/components/common/IconsV2/Website')
);
const WebsiteStroke = dynamic(
  () => import('@/components/common/IconsV2/WebsiteStroke')
);
const ZoomSessionLive = dynamic(
  () =>
    import('@/components/common/IconsV2/ZoomSessionLive/ZoomSessionLive')
);
const Send01 = dynamic(() => import('@/components/common/IconsV2/Send01'));
const CalendarMoney = dynamic(
  () => import('@/components/common/IconsV2/CalendarMoney')
);
const Add = dynamic(() => import('./collection/Add'));
const Announcement = dynamic(() => import('./collection/Announcement'));
const Announcement03 = dynamic(
  () => import('./collection/Announcement03')
);
const Archive = dynamic(() => import('./collection/Archive'));
const ArrowLeft = dynamic(() => import('./collection/ArrowLeft'));
const ArrowUpward = dynamic(() => import('./collection/ArrowUpward'));
const Assistant = dynamic(() => import('./collection/Assistant'));
const BarChart10 = dynamic(() => import('./collection/BarChart10'));
const BarChart12 = dynamic(() => import('./collection/BarChart12'));
const Bell01 = dynamic(() => import('./collection/Bell01'));
const Refund = dynamic(() => import('./collection/Refund'));
const Calendar = dynamic(() => import('./collection/Calendar'));
const Certificate01 = dynamic(() => import('./collection/Certificate01'));
const CheckCircleFilled = dynamic(
  () => import('./collection/CheckCircleFilled')
);
const ChevronDown = dynamic(() => import('./collection/ChevronDown'));
const ChevronRight = dynamic(() => import('./collection/ChevronRight'));
const Close = dynamic(() => import('./collection/Close'));
const Copy02 = dynamic(() => import('./collection/Copy02'));
const CopyClip = dynamic(() => import('./collection/CopyClip'));
const CubeOutline = dynamic(() => import('./collection/CubeOutline'));
const CurrencyDollarCircle = dynamic(
  () => import('./collection/CurrencyDollarCircle')
);
const CursorBox = dynamic(() => import('./collection/CursorBox'));
const Delete = dynamic(() => import('./collection/Delete'));
const Download = dynamic(() => import('./collection/Download'));
const EditPencil = dynamic(() => import('./collection/EditPencil'));
const ErrorAlertWarning = dynamic(
  () => import('./collection/ErrorAlertWarning')
);
const Eye = dynamic(() => import('./collection/Eye'));
const EyeFirst = dynamic(() => import('./collection/EyeFirst'));
const EyeSecond = dynamic(() => import('./collection/EyeSecond'));
const File = dynamic(() => import('./collection/File'));
const UsersAdd = dynamic(() => import('./collection/UsersAdd'));
const FileAttachment04 = dynamic(
  () => import('./collection/FileAttachment04')
);
const Globe02 = dynamic(() => import('./collection/Globe02'));
const HappyFace = dynamic(() => import('./collection/HappyFace'));
const HomeSmile = dynamic(() => import('./collection/HomeSmile'));
const Info = dynamic(() => import('./collection/Info'));
const Like01 = dynamic(() => import('./collection/Like01'));
const Link03 = dynamic(() => import('./collection/Link03'));
const Lock = dynamic(() => import('./collection/Lock'));
const Mail01 = dynamic(() => import('./collection/Mail01'));
const MarkerPin01 = dynamic(() => import('./collection/MarkerPin01'));
const MeatBallMenu = dynamic(() => import('./collection/MeatBallMenu'));
const MessageChatCircle = dynamic(
  () => import('./collection/MessageChatCircle')
);
const MessageHeartCircle = dynamic(
  () => import('./collection/MessageHeartCircle')
);
const MessageTextSquare02 = dynamic(
  () => import('./collection/MessageTextSquare02')
);
const Play = dynamic(() => import('./collection/Play'));
const PublicPage = dynamic(() => import('./collection/PublicPage'));
const Publish = dynamic(() => import('./collection/Publish'));
const Rows01 = dynamic(() => import('./collection/Rows01'));
const Search = dynamic(() => import('./collection/Search'));
const SendSecond1 = dynamic(() => import('./collection/SendSecond1'));
const SentFirst1 = dynamic(() => import('./collection/SentFirst1'));
const Settings01 = dynamic(() => import('./collection/Settings01'));
const Share = dynamic(() => import('./collection/Share'));
const Share04 = dynamic(() => import('./collection/Share04'));
const ShoppingBag01 = dynamic(() => import('./collection/ShoppingBag01'));
const Sort = dynamic(() => import('./collection/Sort'));
const Star07 = dynamic(() => import('./collection/Star07'));
const StarOutline = dynamic(() => import('./collection/StarOutline'));
const Tick = dynamic(() => import('./collection/Tick'));
const Trash01 = dynamic(() => import('./collection/Trash01'));
const TriangleShadowMask = dynamic(
  () => import('./collection/TriangleShadowMask')
);
const Users01 = dynamic(() => import('./collection/Users01'));
const UsersMoney = dynamic(() => import('./collection/UsersMoney'));
const WavingHand = dynamic(() => import('./collection/WavingHand'));
const WhatsappColored = dynamic(
  () => import('./collection/WhatsappColored')
);
const WhatsappGrey = dynamic(() => import('./collection/WhatsappGrey'));
const SlashCircle01 = dynamic(() => import('./collection/SlashCircle01'));
const Reorder = dynamic(() => import('./collection/Reorder'));
const Filter = dynamic(() => import('./collection/Filter'));
const PlayFilled = dynamic(() => import('./collection/PlayFilled'));
const CoinHand = dynamic(() => import('./collection/CoinHand'));
const ArrowNarrowUpRight = dynamic(
  () => import('./collection/ArrowNarrowUpRight')
);
const UserSquare = dynamic(() => import('./collection/UserSquare'));
const UserCircle = dynamic(() => import('./collection/UserCircle'));
const Sale03 = dynamic(() => import('./collection/Sale03'));
const HeartHand = dynamic(() => import('./collection/HeartHand'));
const Courses = dynamic(() => import('./collection/Courses'));
const CreditCardShield = dynamic(
  () => import('./collection/CreditCardShield')
);
const Percent02 = dynamic(() => import('./collection/Percent02'));
const Tag01 = dynamic(() => import('./collection/Tag01'));
const SearchRefraction = dynamic(
  () => import('./collection/SearchRefraction')
);
const PlusCircle = dynamic(() => import('./collection/PlusCircle'));
const PencilEdit = dynamic(() => import('./collection/PencilEdit'));
const ArrowRightWithCircle = dynamic(
  () => import('./collection/ArrowRightWithCircle')
);
const SendMagic01 = dynamic(() => import('./collection/SendMagic01'));
const LightBulb02 = dynamic(() => import('./collection/LightBulb02'));
const Star06 = dynamic(() => import('./collection/Star06'));
const SendMagic03 = dynamic(() => import('./collection/SendMagic03'));
const MessageXSquare = dynamic(
  () => import('./collection/MessageXSquare')
);
const StarsFilled01 = dynamic(() => import('./collection/StarsFilled01'));
const LockUnlocked01 = dynamic(
  () => import('./collection/LockUnlocked01')
);
const ThumbsUp = dynamic(() => import('./collection/ThumbsUp'));
const ThumbsDown = dynamic(() => import('./collection/ThumbsDown'));
const ArrowDown = dynamic(() => import('./collection/ArrowDown'));
const LinkedInColored = dynamic(
  () => import('./collection/LinkedInColored')
);
const FacebookColored = dynamic(
  () => import('./collection/FacebookColored')
);
const DiscordColored = dynamic(
  () => import('./collection/DiscordColored')
);
const SlackColored = dynamic(() => import('./collection/SlackColored'));
const TelegramColored = dynamic(
  () => import('./collection/TelegramColored')
);
const Link01 = dynamic(() => import('./collection/Link01'));
const UploadCloud01Icon = dynamic(() => import('./UploadCloud01Icon'));
const FolderIcon = dynamic(() => import('./FolderIcon'));
const ContainerIcon = dynamic(() => import('./ContainerIcon'));
const Globe06 = dynamic(() => import('./collection/Globe06'));
const VideoCourses = dynamic(() => import('./collection/VideoCourses'));
const MessageChatSquare = dynamic(
  () => import('./collection/MessageChatSquare')
);
const Ticket01 = dynamic(() => import('./collection/Ticket01'));
const Receipt = dynamic(() => import('./collection/Receipt.jsx'));
const CaretDownIcon = dynamic(
  () => import('@/components/common/Icons/CaretDownIcon')
);
const HourGlass01 = dynamic(() => import('./collection/HourGlass01'));
const Bank = dynamic(() => import('./collection/Bank'));
const RefreshCw01 = dynamic(
  () => import('@/components/common/IconsV2/RefreshCw01Icon')
);

// Future V2 -> this map should be automated
export const iconMap = {
  'message-chat-circle': MessageChatCircle,
  container: ContainerIcon,
  file: File,
  bank: Bank,
  folder: FolderIcon,
  archive: Archive,
  eye: Eye,
  publish: Publish,
  sort: Sort,
  info: Info,
  share: Share,
  tick: Tick,
  'meatball-menu': MeatBallMenu,
  'arrow-left': ArrowLeft,
  lock: Lock,
  search: Search,
  delete: Delete,
  'edit-pencil': EditPencil,
  'users-money': UsersMoney,
  'certificate-01': Certificate01,
  'shopping-bag-01': ShoppingBag01,
  'mail-01': Mail01,
  'copy-clip': CopyClip,
  download: Download,
  announcement: Announcement,
  'cursor-box': CursorBox,
  play: Play,
  'bar-chat-10': BarChart10,
  'cube-outline': CubeOutline,
  'like-01': Like01,
  'message-text-square-02': MessageTextSquare02,
  'happy-face': HappyFace,
  'trash-01': Trash01,
  'chevron-down': ChevronDown,
  'star-outline': StarOutline,
  add: Add,
  close: Close,
  money: DollarIconGrey,
  'users-check': UsersCheck,
  video: NPLCourse,
  'npl-calender': NplCalender,
  'trend-up': NPLTrendUp,
  'user-01': User01,
  'user-edit': UserEditIcon,
  'message-heart-circle': MessageHeartCircle,
  'calendar-plus-01': CalendarPlus01,
  'arrow-upward': ArrowUpward,
  'chevron-right': ChevronRight,
  'link-01': Link01,
  'link-03': Link03,
  'file-attachment-04': FileAttachment04,
  'telegram-colored': TelegramColored,
  'slack-colored': SlackColored,
  'discord-colored': DiscordColored,
  'facebook-colored': FacebookColored,
  'linkedin-colored': LinkedInColored,
  'whatsapp-colored': WhatsappColored,
  'whatsapp-grey': WhatsappGrey,
  'error-alert-warning': ErrorAlertWarning,
  'waving-hand': WavingHand,
  'check-circle-filled': CheckCircleFilled,
  'share-04': Share04,
  people: People,
  place: Place,
  pencil: PencilEdit,
  'plus-circle': PlusCircle,
  'video-library': VideoLibrary,
  'zoom-session-live': ZoomSessionLive,
  exercise: Exercise,
  'calendar-event': CalendarEvent,
  website: Website,
  'account-balance-wallet': AccountBalanceWallet,
  readings: Readings,
  'q-and-a': QandA,
  'card-giftcard': GiftCard,
  'minus-circle-fill': MinusCircleFill,
  clock: Clock,
  globe: Globe,
  'coin-hand': CoinHand,
  'live-session': LiveSession,
  'collections-bookmark': CollectionsBookmark,
  'delete-outline': DeleteOutline,
  'more-vertical': MoreVerticle,
  'file-upload': FileUpload,
  'file-upload-icon': FileUploadIcon,
  'upload-cloud-01': UploadCloud01Icon,
  'file-04': File04,
  'edit-document': EditDocument,
  'library-add': LibraryAdd,
  'add-link': AddLink,
  refund: Refund,
  'forms-add-on': FormsAddOn,
  'drag-indicator-reorder': DragIndicatorReorder,
  warning: Warning,
  cached: Cached,
  'check-circle': CheckCircle,
  link: Link,
  camera: CameraIcon,
  'lock-open': UnlockIcon,
  'lock-closed': LockIcon,
  'live-tv': LiveTV,
  'support-agent': SupportAgent,
  'arrow-forward': ArrowForward,
  'icon-l-right': IconLRight,
  'play-video-overlay': PlayOverlayVideo,
  'smart-website': SmartWebsite,
  'icon-l-left': IconLLeft,
  'feature-play': FeaturePlay,
  'sentiment-dissatisfied': SentimentDissatisfied,
  'icon-ne': IconNE,
  'calendar-days': CalendarDays,
  'library-stroke': LibraryStroke,
  'magic-reach': MagicReach,
  hub: Hub,
  'website-stroke': WebsiteStroke,
  'analytics-stoke': AnalyticsStroke,
  'token-nft-web3': TokenNftWeb3,
  'icon-s-left': IconSLeft,
  'external-link': VisitIcon,
  'copy-link': CopyLinkIcon,
  plus: PlusIcon,
  'info-question': InfoQuestion,
  'info-circle': InfoCircleIcon,
  'info-circle-outline': InfoCircleOutlineIcon,
  'book-open': BookOpen,
  'copy-content': CopyContentIcon,
  'copy-page': CopyContentV2,
  dash: Dash,
  'web3-gold': Web3FeatureIcon,
  'chat-integration-gold': ChatIntegrationGoldFeatureIcon,
  'magic-reach-gold': MagicReachFeatureIcon,
  'events-feature-gold': LiveEventsFeatureGoldIcon,
  'content-library-feature-gold': ContentLibraryGoldIcon,
  'monetization-feature-gold': MonetizationFeatureGoldIcon,
  'alert-circle': AlertCircle,
  'hour-glass': HourGlassIcon,
  check: CheckIcon,
  receipt: Receipt,
  'globe-02': Globe02,
  'marker-pin-01': MarkerPin01,
  'copy-02': Copy02,
  reorder: Reorder,
  'users-add': UsersAdd,
  'triangle-shadow-mask': TriangleShadowMask,
  'star-07': Star07,
  'home-smile': HomeSmile,
  'announcement-03': Announcement03,
  calendar: Calendar,
  'rows-01': Rows01,
  'currency-dollar-circle': CurrencyDollarCircle,
  'users-01': Users01,
  'bar-chart-12': BarChart12,
  assistant: Assistant,
  'public-page': PublicPage,
  'settings-01': Settings01,
  'bell-01': Bell01,
  filter: Filter,
  'arrow-narrow-up-right': ArrowNarrowUpRight,
  'send-first-01': SentFirst1,
  'send-second-01': SendSecond1,
  'eye-first': EyeFirst,
  'eye-second': EyeSecond,
  'play-filled': PlayFilled,
  'calendar-money': CalendarMoney,
  'user-square': UserSquare,
  'user-circle': UserCircle,
  'sale-03': Sale03,
  'heart-hand': HeartHand,
  courses: Courses,
  'credit-card-shield': CreditCardShield,
  'send-01': Send01,
  'percent-02': Percent02,
  'tag-01': Tag01,
  'search-refraction': SearchRefraction,
  'slash-circle-01': SlashCircle01,
  'arrow-right-circle': ArrowRightWithCircle,
  'send-magic-01': SendMagic01,
  'lightbulb-02': LightBulb02,
  'star-06': Star06,
  'send-magic-03': SendMagic03,
  'message-x-square': MessageXSquare,
  'stars-filled-01': StarsFilled01,
  'lock-unlocked-01': LockUnlocked01,
  'thumbs-up': ThumbsUp,
  'thumbs-down': ThumbsDown,
  'arrow-down': ArrowDown,
  'globe-06': Globe06,
  'video-courses': VideoCourses,
  'message-chat-square': MessageChatSquare,
  'caret-down': CaretDownIcon,
  'hourglass-01': HourGlass01,
  'refresh-cw-01': RefreshCw01,
  'ticket-01': Ticket01
};
