import { useEffect } from 'react';

export const useOutsideComponentStateClickDetect = (state, callback) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event) => {
      if (state && !state.contains(event.target)) {
        callback(event);
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [callback, state]);
};

/**
 * Hook that alerts clicks outside of the passed ref
 */
const useOutsideComponentClickDetect = (ref, callback) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event) => {
      if (ref?.current && !ref.current.contains(event.target)) {
        callback(event);
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [callback, ref]);
};

/**
 * Hook that alerts clicks outside of the MULTIPLE refs
 */
export const useOutsideMultipleComponentsClickDetect = (refs, cb) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event) => {
      let isInside = refs.some(
        (ref) => ref?.current && ref.current.contains(event.target)
      );
      if (!isInside) {
        cb(event);
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [cb, refs]);
};

export default useOutsideComponentClickDetect;
