import style from './Image.module.scss';

import { PropTypes } from 'prop-types';
import React from 'react';

import { useWindowWidthContext } from '../../../contexts/WindowWidthContext/WindowWidthContext';
import { minDesktopWidth } from '../../../utility/domHelpers';

const Image = ({
  alt,
  mobileImgData,
  desktopImgData,
  customClassNames = ''
}) => {
  const className = 'c-Image';

  const { isDesktopView } = useWindowWidthContext;

  const mobileWebpSrc = mobileImgData?.webpSrc || '';
  const mobileSrc = mobileImgData?.src || '';
  const desktopWebpSrc = desktopImgData?.webpSrc || mobileWebpSrc || '';
  const desktopSrc = desktopImgData?.src || mobileSrc || '';
  const imgSrc = isDesktopView ? desktopSrc : mobileSrc;
  const minDesktopWidthMedia = `(min-width: ${minDesktopWidth}px)`;
  const maxDesktopWidthMedia = `(max-width: ${minDesktopWidth}px)`;

  const mobileMetaData = mobileImgData?.meta || {};
  const desktopMetaData = desktopImgData?.meta || mobileMetaData || {};
  const metaData = isDesktopView ? desktopMetaData : mobileMetaData;

  const restProps = { ...metaData };

  if (!imgSrc) return null;

  return (
    <picture>
      {mobileWebpSrc && (
        <source
          media={maxDesktopWidthMedia}
          type="image/webp"
          srcSet={mobileWebpSrc}
        />
      )}
      {mobileSrc && (
        <source media={maxDesktopWidthMedia} srcSet={mobileSrc} />
      )}
      {desktopWebpSrc && (
        <source
          media={minDesktopWidthMedia}
          type="image/webp"
          srcSet={desktopWebpSrc}
        />
      )}
      {desktopSrc && (
        <source media={minDesktopWidthMedia} srcSet={desktopSrc} />
      )}
      <img
        className={`${className} ${style[className]} ${customClassNames}`}
        src={imgSrc}
        alt={alt}
        {...restProps}
      />
    </picture>
  );
};

export const ImgSrcDataType = {
  src: PropTypes.string,
  webpSrc: PropTypes.string
};

export const ImageDataType = {
  alt: PropTypes.string,
  mobileImgData: PropTypes.shape(ImgSrcDataType),
  desktopImgData: PropTypes.shape(ImgSrcDataType),
  customClassNames: PropTypes.string
};

Image.propTypes = ImageDataType;

export default Image;
