// TODO - Move to Common
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';

import { useWindowWidthContext } from '@/contexts/WindowWidthContext';

import PopOverModal from '@/components/common/PopOverModal';
import Icon from '@/components/npl/Icon';
import NPLButton from '@/components/npl/NPLButton';

import { useOutsideMultipleComponentsClickDetect } from '@/hooks/useOutsideComponentClickDetect';

const OptionMenuV2 = ({
  useButtonTrigger = false,
  buttonTriggerProps = null,
  renderCustomButton = null,
  options,
  customClassContainer = '',
  customTriggerClassNames = '',
  customComponentClassNames = '',
  customTriggerWrapperClassNames = '',
  passedInSetPopperEl = null,
  menuIcon = (
    <Icon name="dots-vertical" width={16} height={16} fill="#1B1B18A6" />
  ),
  close = null,
  placement = 'bottom-end' // Adjust placement as needed
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [popperElement, setPopperElement] = useState(null);
  const referenceElement = useRef(null);
  const { isGtEqMd } = useWindowWidthContext();

  const showButtonTrigger =
    (useButtonTrigger && buttonTriggerProps) || renderCustomButton;

  const handleSetPopperEl = (el) => {
    if (passedInSetPopperEl) passedInSetPopperEl?.(el);
    setPopperElement(el);
  };

  const { styles, attributes } = usePopper(
    referenceElement.current,
    popperElement,
    {
      placement,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 8] // Adjust offset as needed
          }
        }
      ]
    }
  );

  const toggleMenu = (e) => {
    e?.stopPropagation();
    setIsOpen(!isOpen);
  };

  const closePopperMenu = () => {
    if (isGtEqMd) setIsOpen(false);
  };

  const closePopoverModal = () => {
    setIsOpen(false);
  };

  const renderButtonTrigger = () => {
    if (renderCustomButton) return renderCustomButton(toggleMenu);
    if (!useButtonTrigger || !buttonTriggerProps) return;

    const { label, ...rest } = buttonTriggerProps;
    return <NPLButton {...rest} onClick={toggleMenu} buttonText={label} />;
  };

  const renderOptions = () => {
    return options?.map((option, index) => {
      if (!option) return null;

      const {
        label,
        isDanger,
        onClick,
        renderIcon,
        icon,
        isDivider,
        isDisabled
      } = option;
      if (isDivider) {
        return (
          <div
            key={index}
            className="my-8 h-[1px] bg-npl-neutral-light-solid-6"></div>
        );
      }
      return (
        <div
          role="button"
          tabIndex={0}
          key={index}
          onClick={(e) => {
            if (!isDisabled) {
              e.stopPropagation();
              onClick();
              setIsOpen(false);
            }
          }}
          className={classNames('flex items-center rounded-4 px-8 py-12', {
            'hover:bg-npl-error-light-3': isDanger,
            'hover:bg-light-f2 active:bg-light-e2': !isDanger,
            'cursor-not-allowed': isDisabled
          })}>
          {icon && (
            <Icon
              name={icon}
              width={16}
              height={16}
              fill={isDanger ? '#CE2D32' : '#1B1B18A6'}
            />
          )}
          {renderIcon && renderIcon()}
          <p
            className={`ml-8 text-label-md ${
              isDanger
                ? 'text-npl-error-light-11'
                : 'text-npl-text-icon-on-light-surface-primary'
            }`}>
            <span className="">{label}</span>
          </p>
        </div>
      );
    });
  };

  const renderMenu = () => {
    if (!isOpen) return null;

    if (!isGtEqMd)
      return (
        <PopOverModal open={isOpen} onClose={closePopoverModal}>
          {renderOptions()}
        </PopOverModal>
      );
    else
      return createPortal(
        <div
          className="menu-popup z-over-intercom absolute"
          ref={handleSetPopperEl}
          style={{
            ...styles.popper
          }}
          {...attributes.popper}>
          {/* Render your menu items here */}
          <div
            className={`${customClassContainer} right-8 top-[40px] z-[100] min-w-[200px] cursor-pointer overflow-hidden rounded-8 bg-white-default p-4 shadow-dropdown`}>
            {renderOptions()}
          </div>
        </div>,
        document.body
      );
  };

  useOutsideMultipleComponentsClickDetect(
    [
      referenceElement,
      {
        current: popperElement
      }
    ],
    closePopperMenu
  );

  useEffect(() => {
    if (close) setIsOpen(false);
  }, [close]);

  return (
    <div
      className={classNames('c-OptionMenuV2', customComponentClassNames)}>
      <div
        ref={referenceElement}
        className={customTriggerWrapperClassNames}>
        {showButtonTrigger ? (
          renderButtonTrigger()
        ) : (
          <div
            role="button"
            tabIndex={0}
            onClick={toggleMenu}
            className={classNames(
              'p-8 mix-blend-multiply',
              customTriggerClassNames
            )}>
            {menuIcon}
          </div>
        )}
      </div>
      {renderMenu()}
    </div>
  );
};

export default OptionMenuV2;
